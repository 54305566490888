var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DropdownFooter",
    { attrs: { size: _vm.smallSize, "space-between": true } },
    [
      _c("Button", {
        attrs: { size: _vm.tinySize, title: "OK" },
        nativeOn: {
          click: function($event) {
            return _vm.onApply.apply(null, arguments)
          }
        }
      }),
      _vm._v(" "),
      _c("Button", {
        attrs: {
          theme: _vm.secondaryTheme,
          size: _vm.tinySize,
          title: _vm.$t("@Core._.buttons.clear")
        },
        nativeOn: {
          click: function($event) {
            return _vm.onClear.apply(null, arguments)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }