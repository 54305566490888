var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dropdown",
    {
      attrs: {
        "parent-reference": _vm.parentReference,
        visible: _vm.isVisible,
        fixed: _vm.fixedContent
      },
      on: { "click-outside": _vm.onClickOutside }
    },
    [
      _vm._t("body", function() {
        return [
          _vm._t("dropdown", function() {
            return [
              _c("SelectList", {
                attrs: {
                  "items-max-height": _vm.itemsMaxHeight,
                  value: _vm.value,
                  "search-value": _vm.searchValue,
                  items: _vm.options,
                  size: _vm.size,
                  searchable: _vm.searchable,
                  multiselect: _vm.multiselect,
                  "option-key": _vm.optionKey,
                  "option-value": _vm.optionValue
                },
                on: { input: _vm.onValueChange, search: _vm.onSearch },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var index = ref.index
                        var item = ref.item
                        var isSelected = ref.isSelected
                        return [
                          _vm._t("item", null, {
                            item: item,
                            index: index,
                            isSelected: isSelected
                          })
                        ]
                      }
                    },
                    {
                      key: "noDataPlaceholder",
                      fn: function() {
                        return [_vm._t("noDataPlaceholder")]
                      },
                      proxy: true
                    },
                    {
                      key: "noResultsPlaceholder",
                      fn: function() {
                        return [_vm._t("noResultsPlaceholder")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _vm.isFooterVisible
                ? [
                    _vm.multiselect
                      ? _c("MultiselectDropdownFooter", {
                          attrs: { size: _vm.size },
                          on: { clear: _vm.onClear, apply: _vm.onDismiss }
                        })
                      : _c("SelectDropdownFooter", {
                          attrs: { size: _vm.size },
                          on: { clear: _vm.onClear, apply: _vm.onDismiss }
                        })
                  ]
                : _vm._e()
            ]
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }