var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("IconSearch", { attrs: { "fill-color": _vm.searchIconFillColor } }),
      _vm._v(" "),
      _c("div", { class: _vm.activatorClasses }, [
        _c("input", {
          staticClass: "select-list-search__input",
          attrs: { type: "text", placeholder: _vm.placeholder },
          domProps: { value: _vm.value },
          on: {
            focus: _vm.onSearchFocus,
            blur: _vm.onSearchFocusLost,
            input: _vm.onSearch,
            click: function($event) {
              $event.stopPropagation()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("IconFilledClose", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.value !== "",
            expression: "value !== ''"
          }
        ],
        attrs: { "fill-color": _vm.iconClearFillColor },
        nativeOn: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.onClear.apply(null, arguments)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }