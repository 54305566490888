var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Placeholder", {
    attrs: {
      title: _vm.title,
      orientation: _vm.horizontalOrientation,
      subtitle: _vm.subtitle,
      size: _vm.smallSize
    },
    nativeOn: {
      click: function($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "action",
          fn: function() {
            return [_vm._t("action")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }