var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-list" },
    [
      !_vm.isPlaceholderVisible
        ? _c(
            "div",
            { staticClass: "select-list__header" },
            [
              _vm._t("prependHeader"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "select-list__search" },
                [
                  _vm.searchable
                    ? _c("SelectListSearch", {
                        attrs: {
                          placeholder: _vm.searchPlaceholder,
                          value: _vm.searchValue,
                          size: _vm.size
                        },
                        on: { input: _vm.onSearch }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("appendSearchHeader")
                ],
                2
              ),
              _vm._v(" "),
              _vm._t("appendHeader")
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("body", function() {
        return [
          _vm.isPlaceholderVisible
            ? _vm._t("noDataPlaceholder", function() {
                return [_c("SelectListNoDataPlaceholder")]
              })
            : _vm.isSearchPlaceholderVisible
            ? _vm._t("noResultsPlaceholder", function() {
                return [
                  _c("SelectListNoResultsPlaceholder", {
                    on: { clear: _vm.onClearSearch }
                  })
                ]
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isPlaceholderVisible && !_vm.isSearchPlaceholderVisible
            ? _c("DynamicScroller", {
                style: { maxHeight: _vm.itemsMaxHeight },
                attrs: {
                  items: _vm.items,
                  "key-field": _vm.optionKey,
                  "min-item-size": _vm.minItemSize
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        var index = ref.index
                        var active = ref.active
                        return [
                          _c(
                            "DynamicScrollerItem",
                            {
                              attrs: {
                                item: item,
                                active: active,
                                index: index
                              }
                            },
                            [
                              _c("SelectListElement", {
                                key: index,
                                attrs: {
                                  index: index,
                                  size: _vm.size,
                                  value: item,
                                  multiselect: _vm.multiselect,
                                  "option-key": _vm.optionKey,
                                  "option-value": _vm.optionValue,
                                  selected:
                                    _vm.selectedItems[
                                      item[_vm.optionKey] || item
                                    ]
                                },
                                on: { input: _vm.onValueChange },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function(ref) {
                                        var isSelected = ref.isSelected
                                        return [
                                          _vm._t("item", null, {
                                            item: item,
                                            isSelected: isSelected,
                                            index: index
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1829254389
                )
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }