var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListElement",
    {
      attrs: {
        size: _vm.size,
        disabled: _vm.value.disabled,
        selected: _vm.selected && !_vm.multiselect
      },
      nativeOn: {
        click: function($event) {
          $event.preventDefault()
          return _vm.onSelectValue.apply(null, arguments)
        }
      }
    },
    [
      _vm._t(
        "option",
        function() {
          return [
            [
              _vm.multiselect
                ? _c(
                    "ListElementAction",
                    { attrs: { size: _vm.size } },
                    [
                      _c("CheckBox", {
                        attrs: {
                          value: _vm.selected,
                          disabled: _vm.value.disabled
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ListElementDescription",
                [
                  _c("ListElementTitle", {
                    attrs: {
                      hint: _vm.value.hint,
                      size: _vm.size,
                      title: _vm.presentingValue
                    }
                  })
                ],
                1
              )
            ]
          ]
        },
        { isSelected: _vm.selected }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }