var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Select",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function() {
                  return [_vm._t("prepend")]
                },
                proxy: true
              },
              {
                key: "append",
                fn: function() {
                  return [_vm._t("append")]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.parsedValue) }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "dropdownBody",
                fn: function() {
                  return [_vm._t("dropdownBody")]
                },
                proxy: true
              },
              {
                key: "noDataPlaceholder",
                fn: function() {
                  return [_vm._t("noDataPlaceholder")]
                },
                proxy: true
              },
              {
                key: "noResultsPlaceholder",
                fn: function() {
                  return [_vm._t("noResultsPlaceholder")]
                },
                proxy: true
              },
              {
                key: "details",
                fn: function() {
                  return [_vm._t("details")]
                },
                proxy: true
              },
              {
                key: "option",
                fn: function(ref) {
                  var option = ref.option
                  var isSelected = ref.isSelected
                  return [
                    _vm._t(
                      "option",
                      function() {
                        return [
                          _vm.$attrs.multiselect
                            ? _c(
                                "ListElementAction",
                                { attrs: { size: _vm.$attrs.size } },
                                [
                                  _c("CheckBox", {
                                    attrs: { value: isSelected }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ListElementDescription",
                            [
                              _c("ListElementTitle", {
                                attrs: {
                                  size: _vm.$attrs.size,
                                  hint: option.hint,
                                  title: option.value || "#" + option.key
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      { option: option, selected: isSelected }
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        "Select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }