var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Select",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "prepend",
                fn: function() {
                  return [_vm._t("prepend")]
                },
                proxy: true
              },
              {
                key: "value",
                fn: function() {
                  return [
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.parsedValue) }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "option",
                fn: function(ref) {
                  var option = ref.option
                  var isSelected = ref.isSelected
                  return [
                    _c(
                      "ListElementTree",
                      {
                        attrs: {
                          level: option.level,
                          size: _vm.$attrs.size,
                          multiselect: _vm.$attrs.multiselect
                        }
                      },
                      [
                        _vm.$attrs.multiselect
                          ? _c(
                              "ListElementAction",
                              { attrs: { size: _vm.$attrs.size } },
                              [
                                _c("CheckBox", {
                                  attrs: {
                                    value: isSelected,
                                    disabled: option.disabled
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "ListElementDescription",
                          [
                            _c("ListElementTitle", {
                              attrs: {
                                size: _vm.$attrs.size,
                                hint: option.hint,
                                title: option.value || "#" + option.key
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        },
        "Select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }